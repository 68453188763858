<template>
  <div class="page-content">
    <h1 class="page-content__title">Иконки</h1>
    <p>
    В нашей дизайн-системе есть несколько семейств иконок для разных целей. Например, иконки для таб-бара существуют в двух версиях, для активного и неактивного состояния.
    </p>
    <p>
    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FFYx06ImwNSzkrumeAUSrxK%2FPole-Base%3Fnode-id%3D4717%253A59322" allowfullscreen></iframe>
    </p>
    <div>
    <button class="button button-default"><a href="https://www.figma.com/files/997934444069704857/project/27018259/%D0%91%D0%B8%D0%B1%D0%BB%D0%B8%D0%BE%D1%82%D0%B5%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BC%D0%BF%D0%BE%D0%BD%D0%B5%D0%BD%D1%82%D0%BE%D0%B2?fuid=979665218001754536" class="button-link">Перейти в Фигму</a></button>
    </div>
    
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
